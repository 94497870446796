import structureTypes from 'common/constants/structureTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'

export default function getRootEntityTypeByPageType(pageType: PageTypeEnum) {
  switch (pageType) {
    case PageTypeEnum.Inline:
      return [EntityTypeEnum.InlineBody, structureTypes.INLINE]
    case PageTypeEnum.Popup:
      return [structureTypes.REMOTE_POPUP]
    case PageTypeEnum.BlogPostLayout:
      return [structureTypes.BLOG_POST_LAYOUT_BODY]
    default:
      return [structureTypes.BODY]
  }
}
