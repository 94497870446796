import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/Button'
import { ButtonInterface } from 'common/types/entities/button-interface'
import FieldErrors from 'publisher/components/FieldErrors'
import { useClosePopup } from 'publisher/hooks/use-close-popup'
import useOptInSubmit from 'publisher/hooks/use-opt-in-submit-new'
import { usePage } from 'publisher/store'
import { getPageId } from 'publisher/store/page/pageSelectors'

const TEST_ID = 'send-form-button'

export interface SendFormButtonProps {
  entity: ButtonInterface
}

const SendFormButton = ({ entity }: SendFormButtonProps) => {
  const pageId = usePage(getPageId)
  const closePopup = useClosePopup(entity, pageId)

  const [isLoading, errors, submit] = useOptInSubmit({
    optInButtonEntity: entity,
    closePopup,
  })

  return (
    <StyleWrapper
      styles={{ ...entity.margin, ...wrapperStyles }}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonButton
        dataTestId={TEST_ID}
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
        // @ts-ignore
        onClick={submit}
        /* @ts-ignore */
        disabled={isLoading}
        text={entity.text}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        subText={entity.subText}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        alignSelf={entity.alignSelf}
        background={entity.background ?? entity.backgroundColor}
        mobileBackground={
          entity.mobileBackground ?? entity.mobileBackgroundColor
        }
        attrId={entity.htmlAttrId}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        textColor={entity.textColor}
        subTextColor={entity.subTextColor}
        mobileTextColor={entity.mobileTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        textFontFamily={entity.textFontFamily}
        textFontWeight={entity.textFontWeight}
        textFontStyle={entity.textFontStyle}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontWeight={entity.subTextFontWeight}
        subTextFontStyle={entity.subTextFontStyle}
        mobileTextFontFamily={entity.mobileTextFontFamily}
        mobileTextFontWeight={entity.mobileTextFontWeight}
        mobileTextFontStyle={entity.mobileTextFontStyle}
        mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
        mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
        mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
      />
      {/* @ts-ignore */}
      <FieldErrors errors={errors} align="center" />
    </StyleWrapper>
  )
}

export default SendFormButton
