import structureTypes from '../constants/structureTypes'
import { PageTypeEnum } from './PageTypeEnum'

export enum EntityTypeEnum {
  InnerItem = 'InnerItem',
  Carousel = 'Carousel',
  CarouselItem = 'CarouselItem',
  Faq = 'Faq',
  FaqItem = 'FaqItem',
  PricePlanCalculator = 'PricePlanCalculator',
  WebinarRegistrationDate = 'WebinarRegistrationDate',
  Text = 'Text',
  TwitterTweetButton = 'TwitterTweetButton',
  Headline = 'Headline',
  CustomLogin = 'CustomLogin',
  CustomLoginForgotPassword = 'CustomLoginForgotPassword',
  BlogPostImage = 'BlogPostImage',
  ContentTable = 'ContentTable',
  Breadcrumbs = 'Breadcrumbs',
  ContactUs = 'ContactUs',
  Countdown = 'Countdown',
  ContentBox = 'ContentBox',
  TextArea = 'TextArea',
  Field = 'Field',
  Button = 'Button',
  Recaptcha = 'Recaptcha',
  Attachments = 'Attachments',
  OrderSummary = 'OrderSummary',
  BlogPostDate = 'BlogPostDate',
  BlogPostTitle = 'BlogPostTitle',
  Survey = 'Survey',
  UpsellAgreeButton = 'UpsellAgreeButton',
  UpsellDisagreeButton = 'UpsellDisagreeButton',
  Menu = 'Menu',
  BlogPostCategories = 'BlogPostCategories',
  Video = 'Video',
  Image = 'Image',
  BlogContentPlaceholder = 'BlogContentPlaceholder',
  BlogPostContentPlaceholder = 'BlogPostContentPlaceholder',
  BlogPostListing = 'BlogPostListing',
  LatestBlogPosts = 'LatestBlogPosts',
  HorizontalLine = 'HorizontalLine',
  BlogCategoryTitle = 'BlogCategoryTitle',
  BlogCategoryDescription = 'BlogCategoryDescription',
  Comments = 'Comments',
  WebinarSessionLink = 'WebinarSessionLink',
  WebinarSessionVideo = 'WebinarSessionVideo',
  WebinarSessionDateTime = 'WebinarSessionDateTime',
  OfferPrice = 'OfferPrice',
  LatestBlogPostsCarousel = 'LatestBlogPostsCarousel',
  OrderBump = 'OrderBump',
  ContactUsField = 'ContactUsField',
  Audio = 'Audio',
  Product = 'Product',
  WebinarCallToAction = 'WebinarCallToAction',
  SalesRedirectionButton = 'SalesRedirectionButton',
  FacebookComments = 'FacebookComments',
  OptInRecaptcha = 'OptInRecaptcha',
  //will be used instead of Recaptcha in the contact us form
  ContactUsRecaptcha = 'ContactUsRecaptcha',
  CustomerType = 'CustomerType',
  PaymentMethod = 'PaymentMethod',
  Row = 'Row',
  Column = 'Column',
  LanguageSwitcher = 'LanguageSwitcher',
  Section = 'Section',
  RawHtml = 'RawHtml',
  Checkbox = 'Checkbox',
  ShippingFees = 'ShippingFees',
  BulletList = 'BulletList',
  PaymentButton = 'PaymentButton',
  BookingCalendar = 'BookingCalendar',
  Agreement = 'Agreement',
  BookingCalendarPreview = 'BookingCalendarPreview', // only for the dashboard event's preview
  InlineBody = 'InlineBody',
}

/**
 * Return type temporary is array, until inline is fully migrated
 * @param pageType
 */
export function getReadableRootEntityTypeByPageType(pageType: PageTypeEnum) {
  switch (pageType) {
    case PageTypeEnum.BlogHome:
    case PageTypeEnum.BlogStatic:
      return [structureTypes.BLOG_PAGE_BODY]
    case PageTypeEnum.BlogPostBody:
      return [structureTypes.BLOG_POST_BODY]
    case PageTypeEnum.BlogPostLayout:
      return [structureTypes.BLOG_POST_LAYOUT_BODY]
    case PageTypeEnum.Inline:
      return [structureTypes.INLINE, EntityTypeEnum.InlineBody]
    case PageTypeEnum.Popup:
      return [structureTypes.REMOTE_POPUP]
    default:
      return [structureTypes.BODY]
  }
}

export default EntityTypeEnum
