import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { PageTypeEnum } from '../enums/PageTypeEnum'

export const pageTypes = {
  inline: 'inline',
  popup: 'popup',
  squeeze: 'squeeze',
  salesPage: 'sales_page',
  lecture: 'lecture',
  webinarSession: 'webinar_session',
  webinarRegistration: 'webinar_registration',
  webinarThankYou: 'webinar_thank_you_page',
  infoPage: 'info_page',
  optInThankYou: 'opt_in_thank_you',
  offerPage: 'offer_page',
  offerUpSellPage: 'offer_upsell_page',
  offerDownSellPage: 'offer_downsell_page',
  inactiveFunnelPage: 'inactive_funnel_page',
  offerThankYouPage: 'offer_thank_you_page',
  funnelContactUs: 'funnel_contact_us',
  offerInner: 'offer_inner',
  customLogin: 'custom_login',
  blogPostLayout: 'blog_post_layout',
  blogPostBody: 'blog_post_body',
  blogLayout: 'blog_layout',
  blogStatic: 'blog_static',
  blogHome: 'blog_home',
  courseIntroduction: 'course_introduction',

  // used only for migrated pages backward compatibility
  inlineBody: 'inline_body',
}

export const blogPageTypes = [
  pageTypes.blogPostLayout,
  pageTypes.blogPostBody,
  pageTypes.blogLayout,
  pageTypes.blogStatic,
  pageTypes.blogHome,
]

export const pageTypeNode = {
  [pageTypes.inline]: [structureTypes.INLINE, EntityTypeEnum.InlineBody],
  [pageTypes.popup]: [structureTypes.REMOTE_POPUP],
  [pageTypes.squeeze]: [structureTypes.BODY],
  [pageTypes.salesPage]: [structureTypes.BODY],
  [pageTypes.lecture]: [structureTypes.BODY],
  [pageTypes.webinarSession]: [structureTypes.BODY],
  [pageTypes.webinarRegistration]: [structureTypes.BODY],
  [pageTypes.webinarThankYou]: [structureTypes.BODY],
  [pageTypes.infoPage]: [structureTypes.BODY],
  [pageTypes.offerPage]: [structureTypes.BODY],
  [pageTypes.offerInner]: [structureTypes.BODY],
  [pageTypes.offerUpSellPage]: [structureTypes.BODY],
  [pageTypes.offerDownSellPage]: [structureTypes.BODY],
  [pageTypes.inactiveFunnelPage]: [structureTypes.BODY],
  [pageTypes.optInThankYou]: [structureTypes.BODY],
  [pageTypes.offerThankYouPage]: [structureTypes.BODY],
  [pageTypes.customLogin]: [structureTypes.BODY],
  [pageTypes.blogPostBody]: [structureTypes.BODY],
  [pageTypes.blogStatic]: [structureTypes.BODY],
  [pageTypes.blogHome]: [structureTypes.BODY],
  [pageTypes.blogPostLayout]: [structureTypes.BODY],
  [pageTypes.blogLayout]: [structureTypes.BODY],
  [pageTypes.courseIntroduction]: [structureTypes.BODY],
  [PageTypeEnum.FunnelContactUs]: [structureTypes.BODY],
  [PageTypeEnum.NotFound]: [structureTypes.BODY],
  [PageTypeEnum.LinkInBio]: [structureTypes.BODY],
}

export const pageTypesWithAutomationRules = []
