import { css } from 'styled-components'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { borderTypeKeys } from 'common/constants/settings'
import { TransformStyleEnum } from '../enums/TransformStyleEnum'
import { CommonContainerInterface } from '../types/entities/EntityInterface'
import {
  AlignSelfType,
  AlignType,
  BorderRadiusType,
  BorderType,
  FullBorderRadiusType,
  HoverType,
  MarginType,
  PaddingType,
  TextAlign,
} from '../types/styleTypes'

export function getBorderType(borderType: string) {
  switch (borderType) {
    case borderTypeKeys.fullBorder:
      return ''
    case borderTypeKeys.bottomOnly:
      return css`
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
      `
    case borderTypeKeys.topOnly:
      return css`
        border-bottom-width: 0;
        border-left-width: 0;
        border-right-width: 0;
      `
    case borderTypeKeys.topAndBottom:
      return css`
        border-left-width: 0;
        border-right-width: 0;
      `
    default:
      break
  }
}

export const borderRadiusCss = css<{
  borderRadius?: BorderRadiusType | FullBorderRadiusType
}>`
  ${p =>
    p.borderRadius &&
    (typeof p.borderRadius === 'number'
      ? `border-radius: ${p.borderRadius}px;`
      : typeof p.borderRadius === 'object' &&
        css`
          border-top-left-radius: ${p.borderRadius.borderTopLeftRadius}px;
          border-top-right-radius: ${p.borderRadius.borderTopRightRadius}px;
          border-bottom-left-radius: ${p.borderRadius.borderBottomLeftRadius}px;
          border-bottom-right-radius: ${p.borderRadius
            .borderBottomRightRadius}px;
        `)}
`

export const mobileBorderRadiusCss = css<{
  mobileBorderRadius?: BorderRadiusType | FullBorderRadiusType
}>`
  ${p =>
    p.mobileBorderRadius &&
    (typeof p.mobileBorderRadius === 'number'
      ? `border-radius: ${p.mobileBorderRadius}px;`
      : typeof p.mobileBorderRadius === 'object' &&
        css`
          border-top-left-radius: ${p.mobileBorderRadius.borderTopLeftRadius}px;
          border-top-right-radius: ${p.mobileBorderRadius
            .borderTopRightRadius}px;
          border-bottom-left-radius: ${p.mobileBorderRadius
            .borderBottomLeftRadius}px;
          border-bottom-right-radius: ${p.mobileBorderRadius
            .borderBottomRightRadius}px;
        `)}
`

export const borderCss = css<{ border?: Partial<BorderType> }>`
  ${p =>
    p.border &&
    typeof p.border.radius === 'number' &&
    `border-radius: ${p.border.radius}px;`}
  ${p =>
    p.border &&
    typeof p.border.radius === 'object' &&
    css`
      border-top-left-radius: ${p.border.radius.borderTopLeftRadius}px;
      border-top-right-radius: ${p.border.radius.borderTopRightRadius}px;
      border-bottom-left-radius: ${p.border.radius.borderBottomLeftRadius}px;
      border-bottom-right-radius: ${p.border.radius.borderBottomRightRadius}px;
    `}
  ${p => p.border && p.border.style && `border-style: ${p.border.style};`}
  ${p =>
    p.border &&
    typeof p.border.width === 'number' &&
    `border-width: ${p.border.width}px;`}
  ${p => p.border && p.border.type && getBorderType(p.border.type)}
  ${p => p.border && p.border.color && `border-color: ${p.border.color};`}
`

export const mobileBorderCss = css<{ mobileBorder?: Partial<BorderType> }>`
  ${p =>
    p.mobileBorder &&
    typeof p.mobileBorder.radius === 'number' &&
    `border-radius: ${p.mobileBorder.radius}px;`}
  ${p =>
    p.mobileBorder &&
    p.mobileBorder.style &&
    `border-style: ${p.mobileBorder.style};`}
    ${p =>
    p.mobileBorder &&
    typeof p.mobileBorder.width === 'number' &&
    `border-width: ${p.mobileBorder.width}px;`}
    ${p =>
    p.mobileBorder &&
    p.mobileBorder.color &&
    `border-color: ${p.mobileBorder.color};`}
    ${p =>
    p.mobileBorder &&
    typeof p.mobileBorder.radius === 'object' &&
    css`
      border-top-left-radius: ${p.mobileBorder.radius.borderTopLeftRadius}px;
      border-top-right-radius: ${p.mobileBorder.radius.borderTopRightRadius}px;
      border-bottom-left-radius: ${p.mobileBorder.radius
        .borderBottomLeftRadius}px;
      border-bottom-right-radius: ${p.mobileBorder.radius
        .borderBottomRightRadius}px;
    `}
    ${p =>
    p.mobileBorder && p.mobileBorder.type && getBorderType(p.mobileBorder.type)}
`

export const marginCss = css<{ margin?: MarginType }>`
  ${p =>
    p.margin &&
    `margin: ${p.margin.marginTop}px ${p.margin.marginRight}px ${p.margin.marginBottom}px
    ${p.margin.marginLeft}px`};
`

export const mobileMarginCss = css<{ mobileMargin?: Partial<MarginType> }>`
  ${p =>
    p.mobileMargin &&
    typeof p.mobileMargin.marginTop === 'number' &&
    `margin-top: ${p.mobileMargin.marginTop}px`};
  ${p =>
    p.mobileMargin &&
    typeof p.mobileMargin.marginRight === 'number' &&
    `margin-right: ${p.mobileMargin.marginRight}px`};
  ${p =>
    p.mobileMargin &&
    typeof p.mobileMargin.marginBottom === 'number' &&
    `margin-bottom: ${p.mobileMargin.marginBottom}px`};
  ${p =>
    p.mobileMargin &&
    typeof p.mobileMargin.marginLeft === 'number' &&
    `margin-left: ${p.mobileMargin.marginLeft}px`};
`

export type FontProps = {
  fontSize?: number
  mobileFontSize?: number
  color?: string
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  letterSpacing?: number
  mobileColor?: string
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
  mobileLetterSpacing?: number
}

export const fontSizeCss = css<{ fontSize?: number }>`
  ${p => p.fontSize && `font-size: ${p.fontSize}px;`}
`

export const mobileFontSizeCss = css<{ mobileFontSize?: number }>`
  ${p => p.mobileFontSize && `font-size: ${p.mobileFontSize}px;`}
`

export const fontCss = css<FontProps>`
  ${p => p.fontSize && `font-size: ${p.fontSize}px;`}
  ${p => p.color && `color: ${p.color};`}
  ${p => p.fontFamily && `font-family: ${wrapFontFamily(p.fontFamily)};`}
    ${p => p.fontWeight && `font-weight: ${p.fontWeight};`}
    ${p => p.fontStyle && `font-style: ${p.fontStyle};`}
    ${p => p.letterSpacing && `letter-spacing: ${p.letterSpacing};`}
`

export const mobileFontCss = css<FontProps>`
  ${p => p.mobileFontSize && `font-size: ${p.mobileFontSize}px;`}
  ${p => p.mobileColor && `color: ${p.mobileColor};`}
  ${p =>
    p.mobileFontFamily && `font-family: ${wrapFontFamily(p.mobileFontFamily)};`}
    ${p => p.mobileFontWeight && `font-weight: ${p.mobileFontWeight};`}
    ${p => p.mobileFontStyle && `font-style: ${p.mobileFontStyle};`}
  ${p => p.mobileLetterSpacing && `letter-spacing: ${p.mobileLetterSpacing};`}
`

export const hoverCss = css<{ hover?: HoverType }>`
  &:hover {
    ${p => p.hover?.textColor && `color: ${p.hover.textColor}`};
    ${p =>
      p.hover?.backgroundColor && `background: ${p.hover.backgroundColor}`};
  }
  &:hover small {
    ${p => p.hover?.subTextColor && `color: ${p.hover.subTextColor}`};
  }
`

export const mobileHoverCss = css<{ mobileHover?: HoverType }>`
  &:hover {
    ${p => p.mobileHover?.textColor && `color: ${p.mobileHover.textColor}`};
    ${p =>
      p.mobileHover?.backgroundColor &&
      `background: ${p.mobileHover.backgroundColor}`};
    &:hover small {
      ${p =>
        p.mobileHover?.subTextColor && `color: ${p.mobileHover.subTextColor}`};
    }
  }
`

export const hoverTransformCss = css<{ hover?: HoverType }>`
  &:hover {
    ${p =>
      p.hover?.transform &&
      p.hover?.offset &&
      p.hover.transform !== TransformStyleEnum.none &&
      `transform: ${p.hover.transform}(${
        p.hover.transform === TransformStyleEnum.scale
          ? `1.${p.hover.offset < 10 ? `0${p.hover.offset}` : p.hover.offset}`
          : `${p.hover.offset}px`
      });`};
  }
`

export const mobileHoverTransformCss = css<{ mobileHover?: HoverType }>`
  &:hover {
    ${p =>
      p.mobileHover?.transform &&
      p.mobileHover?.offset &&
      p.mobileHover.transform !== TransformStyleEnum.none &&
      `transform: ${p.mobileHover.transform}(${
        p.mobileHover.transform === TransformStyleEnum.scale
          ? `1.${
              p.mobileHover.offset < 10
                ? `0${p.mobileHover.offset}`
                : p.mobileHover.offset
            }`
          : `${p.mobileHover.offset}px`
      });`};
  }
`

export const paddingsCss = css<{ padding?: Partial<PaddingType> }>`
  ${p =>
    p.padding &&
    p.padding.paddingTop &&
    `padding-top: ${p.padding.paddingTop}px`};
  ${p =>
    p.padding &&
    p.padding.paddingRight &&
    `padding-right: ${p.padding.paddingRight}px`};
  ${p =>
    p.padding &&
    p.padding.paddingBottom &&
    `padding-bottom: ${p.padding.paddingBottom}px`};
  ${p =>
    p.padding &&
    p.padding.paddingLeft &&
    `padding-left: ${p.padding.paddingLeft}px`};
`

export const mobilePaddingsCss = css<{ mobilePadding?: Partial<PaddingType> }>`
  ${p =>
    p.mobilePadding &&
    typeof p.mobilePadding.paddingTop === 'number' &&
    `padding-top: ${p.mobilePadding.paddingTop}px`};
  ${p =>
    p.mobilePadding &&
    typeof p.mobilePadding.paddingRight === 'number' &&
    `padding-right: ${p.mobilePadding.paddingRight}px`};
  ${p =>
    p.mobilePadding &&
    typeof p.mobilePadding.paddingBottom === 'number' &&
    `padding-bottom: ${p.mobilePadding.paddingBottom}px`};
  ${p =>
    p.mobilePadding &&
    typeof p.mobilePadding.paddingLeft === 'number' &&
    `padding-left: ${p.mobilePadding.paddingLeft}px`};
`

export const colorCss = css<{ color?: string }>`
  ${p => p.color && `color: ${p.color}; `}
`

export const mobileColorCss = css<{ mobileColor?: string }>`
  ${p => p.mobileColor && `color: ${p.mobileColor}`}
`

export const backgroundColorCss = css<{ backgroundColor?: string }>`
  ${p => p.backgroundColor && `background-color: ${p.backgroundColor}`};
`

export const backgroundCss = css<{ background?: string }>`
  ${p => p.background && `background: ${p.background}`};
`

export const mobileBackgroundCss = css<{ mobileBackground?: string }>`
  ${p => p.mobileBackground && `background: ${p.mobileBackground}`};
`

export const mobileBackgroundColorCss = css<{ mobileBackgroundColor?: string }>`
  ${p =>
    p.mobileBackgroundColor && `background-color: ${p.mobileBackgroundColor}`};
`

export const boxShadowCss = css<{ boxShadow?: string }>`
  ${p => p.boxShadow && `box-shadow: ${p.boxShadow}`};
`

export const mobileBoxShadowCss = css<{ mobileBoxShadow?: string }>`
  ${p => p.mobileBoxShadow && `box-shadow: ${p.mobileBoxShadow}`};
`
export const lineHeightCss = css<{ lineHeight?: number }>`
  ${p => p.lineHeight && `line-height: ${p.lineHeight}px;`}
`

export const mobileLineHeightCss = css<{ mobileLineHeight?: number }>`
  ${p => p.mobileLineHeight && `line-height: ${p.mobileLineHeight}px;`}
`

const getPlaceholderCssByColor = (color: string) => css`
  &::placeholder {
    color: ${color};
  }
`

export const placeholderColorCss = css<{
  placeholderColor?: string
  color?: string
}>`
  ${p =>
    (p.placeholderColor && getPlaceholderCssByColor(p.placeholderColor)) ||
    (p.color && getPlaceholderCssByColor(p.color))}
`

// looks different than placeholderColorCss because we inherit placeholder color
export const mobilePlaceholderColorCss = css<{
  mobilePlaceholderColor?: string
}>`
  ${p =>
    p.mobilePlaceholderColor &&
    getPlaceholderCssByColor(p.mobilePlaceholderColor)}
`

export const containerCss = css<CommonContainerInterface>`
  ${backgroundColorCss}
  ${paddingsCss}
  ${borderCss}
  ${boxShadowCss}
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
    ${mobileBoxShadowCss}
    ${mobileBackgroundColorCss}
    ${mobileBorderCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
      ${mobileBoxShadowCss}
      ${mobileBackgroundColorCss}
      ${mobileBorderCss}
    `}
`

export interface TextProps {
  fontSize?: number
  mobileFontSize?: number
  color?: string
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  letterSpacing?: number
  textAlign?: TextAlign | AlignType
  mobileColor?: string
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
  mobileTextAlign?: TextAlign | AlignType
  lineHeight?: number
  mobileLineHeight?: number
  mobileLetterSpacing?: number
}

export const textCss = css<TextProps>`
  ${p => p.fontSize && `font-size: ${p.fontSize}px;`}
  ${p => p.color && `color: ${p.color};`}
  ${p => p.fontFamily && `font-family: ${wrapFontFamily(p.fontFamily)};`}
    ${p => p.fontWeight && `font-weight: ${p.fontWeight};`}
    ${p => p.fontStyle && `font-style: ${p.fontStyle};`}
    ${p =>
    typeof p.letterSpacing === 'number' &&
    `letter-spacing: ${p.letterSpacing}px;`}
    ${p => p.textAlign && `text-align: ${p.textAlign};`}
  ${p => p.lineHeight && `line-height: ${p.lineHeight}px;`}
`

export const mobileTextCss = css<TextProps>`
  ${p => p.mobileFontSize && `font-size: ${p.mobileFontSize}px;`}
  ${p => p.mobileColor && `color: ${p.mobileColor};`}
  ${p =>
    p.mobileFontFamily && `font-family: ${wrapFontFamily(p.mobileFontFamily)};`}
  ${p => p.mobileFontWeight && `font-weight: ${p.mobileFontWeight};`}
  ${p => p.mobileFontStyle && `font-style: ${p.mobileFontStyle};`}
  ${p =>
    typeof p.mobileLetterSpacing === 'number' &&
    `letter-spacing: ${p.mobileLetterSpacing}px;`}
  ${p => p.mobileTextAlign && `text-align: ${p.mobileTextAlign};`}
  ${p => p.mobileLineHeight && `line-height: ${p.mobileLineHeight}px;`}
`

export const justifyContentCss = css<{ justifyContent?: AlignType }>`
  ${p => p.justifyContent && `justify-content: ${p.justifyContent};`}
`

export const mobileJustifyContentCss = css<{
  mobileJustifyContent?: AlignType
}>`
  ${p =>
    p.mobileJustifyContent && `justify-content: ${p.mobileJustifyContent};`}
`

export interface DeprecatedTextProps {
  fontSize?: string
  mobileFontSize?: string
  color?: string
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  letterSpacing?: string
  textAlign?: TextAlign
  lineHeight?: string
  mobileLineHeight?: string
  mobileColor?: string
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
  mobileLetterSpacing?: string
  mobileTextAlign?: TextAlign
}

export const deprecatedTextCss = css<DeprecatedTextProps>`
  ${p => p.fontSize && `font-size: ${p.fontSize};`}
  ${p => p.color && `color: ${p.color};`}
  ${p => p.fontFamily && `font-family: ${p.fontFamily};`}
    ${p => p.fontWeight && `font-weight: ${p.fontWeight};`}
    ${p => p.fontStyle && `font-style: ${p.fontStyle};`}
    ${p => p.letterSpacing && `letter-spacing: ${p.letterSpacing};`}
    ${p => p.textAlign && `text-align: ${p.textAlign};`}
    ${p => p.lineHeight && `line-height: ${p.lineHeight};`}
`

export const deprecatedMobileTextCss = css<DeprecatedTextProps>`
  ${p => p.mobileFontSize && `font-size: ${p.mobileFontSize};`}
  ${p => p.mobileColor && `color: ${p.mobileColor};`}
  ${p => p.mobileFontFamily && `font-family: ${p.fontFamily};`}
  ${p => p.mobileFontWeight && `font-weight: ${p.fontWeight};`}
  ${p => p.mobileFontStyle && `font-style: ${p.fontStyle};`}
  ${p => p.mobileLetterSpacing && `letter-spacing: ${p.mobileLetterSpacing};`}
  ${p => p.mobileTextAlign && `text-align: ${p.mobileTextAlign};`}
  ${p => p.mobileLineHeight && `line-height: ${p.mobileLineHeight};`}
`
interface InactivePaginationArrowCssProps {
  paginationActiveColor: string
  mobilePaginationActiveColor?: string
  paginationActiveBackgroundColor: string
  mobilePaginationActiveBackgroundColor?: string
  paginationInactiveColor: string
  mobilePaginationInactiveColor?: string
  paginationInactiveBackgroundColor: string
  mobilePaginationInactiveBackgroundColor?: string
}

export const InactivePaginationArrowCss = css<InactivePaginationArrowCssProps>`
  ${p => p.paginationInactiveColor && `color: ${p.paginationInactiveColor};`}
  ${p =>
    p.paginationInactiveBackgroundColor &&
    `background-color: ${p.paginationInactiveBackgroundColor};`}
  ${p =>
    p.paginationInactiveColor &&
    `border: 1px solid ${p.paginationInactiveColor};`}
   &:hover {
    ${p =>
      p.paginationActiveBackgroundColor &&
      `background-color: ${p.paginationActiveBackgroundColor};`}
    ${p => p.paginationActiveColor && `color: ${p.paginationActiveColor};`}
    ${p =>
      p.paginationActiveColor &&
      `border: 1px solid ${p.paginationActiveColor};`}
  }
`

export const MobileInactivePaginationArrowCss = css<InactivePaginationArrowCssProps>`
  ${p =>
    p.mobilePaginationInactiveColor &&
    `color: ${p.mobilePaginationInactiveColor};`}
  ${p =>
    p.mobilePaginationInactiveBackgroundColor &&
    `background-color: ${p.mobilePaginationInactiveBackgroundColor};`}
  ${p =>
    p.mobilePaginationInactiveColor &&
    `border: 1px solid ${p.mobilePaginationInactiveColor};`}
   &:hover {
    ${p =>
      p.mobilePaginationActiveBackgroundColor &&
      `background-color: ${p.mobilePaginationActiveBackgroundColor};`}
    ${p =>
      p.mobilePaginationActiveColor &&
      `color: ${p.mobilePaginationActiveColor};`}
    ${p =>
      p.mobilePaginationActiveColor &&
      `border: 1px solid ${p.mobilePaginationActiveColor};`}
  }
`

interface ActivePaginationArrowCssProps {
  paginationActiveColor: string
  mobilePaginationActiveColor?: string
  paginationActiveBackgroundColor: string
  mobilePaginationActiveBackgroundColor?: string
}

export const ActivePaginationArrowCss = css<ActivePaginationArrowCssProps>`
  ${p => p.paginationActiveColor && `color: ${p.paginationActiveColor};`}
  ${p =>
    p.paginationActiveBackgroundColor &&
    `background-color: ${p.paginationActiveBackgroundColor};`}
  ${p =>
    p.paginationActiveColor && `border: 1px solid ${p.paginationActiveColor};`}
`
export const ActiveMobilePaginationArrowCss = css<ActivePaginationArrowCssProps>`
  ${p =>
    p.mobilePaginationActiveColor && `color: ${p.mobilePaginationActiveColor};`}
  ${p =>
    p.mobilePaginationActiveBackgroundColor &&
    `background-color: ${p.mobilePaginationActiveBackgroundColor};`}
  ${p =>
    p.mobilePaginationActiveColor &&
    `border: 1px solid ${p.mobilePaginationActiveColor};`}
`

interface showFaqIconCssProps {
  showIcons: boolean
  mobileShowIcons: boolean
}

export const showFaqIconCss = css<showFaqIconCssProps>`
  display: ${p => (p.showIcons ? 'inline-block' : 'none')};

  ${p => p.theme.phone} {
    display: ${p => (p.mobileShowIcons ? 'inline-block' : 'none')};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      display: ${p.mobileShowIcons ? 'inline-block' : 'none'};
    `}
`

export const splitMarginsAndRestStyles = (styles: Record<string, string>) => {
  const { marginTop, marginLeft, marginBottom, marginRight, ...restStyles } =
    styles
  return [{ marginTop, marginLeft, marginBottom, marginRight }, restStyles]
}

export function extractMarginFromOldStyles(styles: Record<string, string>) {
  const [margin] = splitMarginsAndRestStyles(styles)
  const newMargin: Partial<MarginType> = {}
  if (margin.marginTop && !isNaN(parseInt(margin.marginTop))) {
    newMargin.marginTop = parseInt(margin.marginTop)
  }
  if (margin.marginRight && !isNaN(parseInt(margin.marginRight))) {
    newMargin.marginRight = parseInt(margin.marginRight)
  }
  if (margin.marginBottom && !isNaN(parseInt(margin.marginBottom))) {
    newMargin.marginBottom = parseInt(margin.marginBottom)
  }
  if (margin.marginLeft && !isNaN(parseInt(margin.marginLeft))) {
    newMargin.marginLeft = parseInt(margin.marginLeft)
  }

  return newMargin
}

export const alignSelfCss = css<{ alignSelf?: AlignSelfType }>`
  ${p => p.alignSelf && `justify-content: ${p.alignSelf}`};
`

export const alignItemsCss = css<{ alignItems?: AlignSelfType }>`
  ${p => p.alignItems && `align-items: ${p.alignItems}`};
`

export const mobileAlignSelfCss = css<{ mobileAlignSelf?: AlignSelfType }>`
  ${p => p.mobileAlignSelf && `justify-content: ${p.mobileAlignSelf}`};
`

export const textAlignCss = css<{ textAlign?: AlignType }>`
  ${p => p.textAlign && `text-align: ${p.textAlign || 'left'}`};
`

export const mobileTextAlignCss = css<{ mobileTextAlign?: AlignType }>`
  ${p => p.mobileTextAlign && `text-align: ${p.mobileTextAlign || 'left'}`};
`

export const verticalSpacing = css<{ verticalSpacing?: number }>`
  ${p => p.verticalSpacing && `margin-bottom: ${p.verticalSpacing}px`};
`

export const mobileVerticalSpacing = css<{ mobileVerticalSpacing?: number }>`
  ${p =>
    p.mobileVerticalSpacing && `margin-bottom: ${p.mobileVerticalSpacing}px`};
`

interface BulletListIconProps {
  listItemIconColor?: string
  listItemIconSize?: number
}

export const bulletListIconCss = css<BulletListIconProps>`
  ${p => p.listItemIconColor && `color: ${p.listItemIconColor}`};
  ${p => p.listItemIconSize && `font-size: ${p.listItemIconSize}px`};
`

export const mobileBulletListIconCss = css<{
  mobileListItemIconColor?: string
}>`
  ${p => p.mobileListItemIconColor && `color: ${p.mobileListItemIconColor}`};
`
