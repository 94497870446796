import { useRollbar } from '@rollbar/react'
import React from 'react'
import { ButtonActionTypeEnum } from 'common/enums/ButtonActionTypeEnum'
import { ButtonInterface } from 'common/types/entities/button-interface'
import BlogLinkButton from 'publisher/components/entities/button-new/blog-link-button'
import CustomLoginButton from 'publisher/components/entities/button-new/custom-login-button'
import DownloadFileButton from 'publisher/components/entities/button-new/download-file-button'
import NextStepRedirectionButton from 'publisher/components/entities/button-new/next-step-redirection-button'
import OpenUrlButton from 'publisher/components/entities/button-new/open-url-button'
import SendFormButton from 'publisher/components/entities/button-new/send-form-button'
import ShowPopupButton from 'publisher/components/entities/button-new/show-popup-button'
import { useDelay } from 'publisher/hooks/useDelay'
import ContactUsButton from 'publisher/pages/contact-us/components/ContactUsButton'

export interface OptionButtonNewProps {
  entity: ButtonInterface
}

const OptInButtonNew = ({ entity }: OptionButtonNewProps) => {
  const rollbar = useRollbar()
  const isVisible = useDelay(entity.delay)

  if (!isVisible) {
    return <div />
  }

  switch (entity.action) {
    case ButtonActionTypeEnum.NextStepRedirection:
      return <NextStepRedirectionButton entity={entity} />
    case ButtonActionTypeEnum.OpenUrl:
      return <OpenUrlButton entity={entity} />
    case ButtonActionTypeEnum.DownloadFile:
      return <DownloadFileButton entity={entity} />
    case ButtonActionTypeEnum.SendForm:
      return <SendFormButton entity={entity} />
    case ButtonActionTypeEnum.ShowPopup:
      return <ShowPopupButton entity={entity} />
    case ButtonActionTypeEnum.BlogLink:
      return <BlogLinkButton entity={entity} />
    case ButtonActionTypeEnum.Login:
    case ButtonActionTypeEnum.ResetPassword:
    case ButtonActionTypeEnum.ConfirmRegistration:
    case ButtonActionTypeEnum.ResetRequest:
      return <CustomLoginButton entity={entity} />
    case ButtonActionTypeEnum.ContactUs:
      return <ContactUsButton entity={entity} />
    default:
      return <ContactUsButton entity={entity} />
    // rollbar.error('Unknown button action type', entity.action)
  }
}

export default OptInButtonNew
